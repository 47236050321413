<template>
  <div v-if="loading">{{ $gettext('Caricamento dei dati della pagina...') }}</div>

  <div v-if="!loading" clas="news-wrapper container">
    <div class="page">
      <img class="headerImage" :src="headerImage" />
      <h1 id="title" v-html="title"></h1>
      <div id="body" v-html="body"></div>
      <div id="gallery_wrap" v-if="activeGallery">
        <carousel :items-to-show="1" :wrap-around="true">
          <slide v-for="img in gallery" v-bind:key="img.id">
            <img :src="img.url" />
          </slide>

          <template #addons>
            <navigation />
            <pagination />
          </template>
        </carousel>
        <div></div>
      </div>
    </div>
  </div>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

export default {
  components: { Carousel, Slide, Pagination, Navigation },
  data() {
    return {
      loading: false,
      headerImage: this.$imgPlaceHolder,
      title: '',
      body: '',
      gallery: [],
      activeGallery: false,
    };
  },

  name: 'page-details',

  methods: {
    domDecoder(str) {
      let parser = new DOMParser();
      let dom = parser.parseFromString(
        '<!doctype html><body>' + str,
        'text/html',
      );
      return dom.body.textContent;
    },
    async getImage(id) {
      return this.axios
        .get(this.api.getMediaUrl + id)
        .then((res) => {
          return res.data.media_details.sizes;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
    getPageData() {
      this.loading = true;

      this.axios
        .get(this.api.pageDetails + this.id+"?" + this.$store.getters['auth/getLanguageRest'])
        .then((res) => {
          this.title = this.domDecoder(res.data.title.rendered);
          //this.body = this.domDecoder(res.data.content.rendered);
          this.body = res.data.content.rendered;

          if (res.data.featured_media !== 0) {
            this.getImage(res.data.featured_media).then((sizes) => {
              this.headerImage = sizes.medium.source_url;
            });
          }
          if (
            res.data.acf.gallery_pagine !== null &&
            res.data.acf.gallery_pagine.length > 0
          ) {
            this.getGallery(res.data.acf.gallery_pagine);
            this.activeGallery = true;
          } else {
            this.activeGallery = false;
          }
          this.loading = false;
        })
        .catch((err) => {
          console.log('Errror: ' + err);
        });
    },
    getGallery(items) {
      items.forEach((item) => {
        this.getImage(item).then((sizes) => {
          this.gallery.push({
            id: item,
            url: sizes.medium.source_url,
            alt: '',
            title: '',
          });
        });
      });
    },
  },

  created() {
    this.id = this.$route.params.id;
  },

  mounted() {
    this.getPageData();
  },
};
</script>

<style scoped>
img {
  width: 100%;
  max-width: 100%;
}
.page {
  text-align: left;
}

#title,
#body {
  padding: 20px;
}
</style>
